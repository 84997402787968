<template>
    <v-div>
        <p v-if="mostrarFiltro" class=" mb-6">Movimientos detallado por producto por tipo transaccion</p>
        <v-card>
            <v-card-subtitle v-if="mostrarFiltro">Filtros</v-card-subtitle>
            <v-card-text v-if="mostrarFiltro">
                <v-form ref="form">
                    <v-row>

                        <v-col cols="4">
                            <dos-fechas
                                :fechas="[filtros.fecha.desde,filtros.fecha.hasta]"
                                @getFechas="getFechas" :icon="false"></dos-fechas>
                        </v-col>
                        <v-col cols="4">
                            <AreaSelect :clearable="true" v-model="filtros.idArea" :dense="true" :outlined="true">
                            </AreaSelect>
                        </v-col>
                        <v-col cols="4">
                            <SelectProductoSucursal :idPadre="store.state.sucursalSelect.id" :clearable="true"
                               v-model="filtros.ProductoSucursal" :dense="true" :outlined="true">
                            </SelectProductoSucursal>
                        </v-col>
                        <v-col cols="12" class="text-right">
                            <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary"
                                @click="buscarDatos()">
                                Buscar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider v-if="mostrarFiltro"></v-divider>
            <v-card-text>
                <tabla-reporte @GetVer="getVer" :acciones="['VER']" :nombreReporte="nombreReporte" :cabeceraIn="cabecera"
                    :datosIn="datos" :exportar="['EXCEL', 'PDF']"></tabla-reporte>

            </v-card-text>
            <ModalViewAjuste :VerButton="false" ref="ModalViewAjusteRef"></ModalViewAjuste>
            <ModalViewCompra :VerButton="false" ref="ModalViewCompraRef"></ModalViewCompra>
            <ModalViewTranslado :VerButton="false" ref="ModalViewTransladoRef"></ModalViewTranslado>
            
        </v-card>
    </v-div>
</template>
    
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue';
import { ref } from '@vue/composition-api';
import MovimientoServices from '@/api/servicios/MovimientoServices';
import store from '@/store';
import DosFechas from '@/components/DosFechas.vue';
import FuncionesGenerales from '@/funciones/funciones'
import moment from 'moment';
import SelectProductoSucursal from '@/views/sistema/producto/catalogo/producto/componentes/SelectProductoSucursal.vue';
import AreaSelect from '../../archivo/area/componentes/AreaSelect.vue';
import ModalViewAjuste from '../../ajuste/ajuste/componentes/ModalViewAjuste.vue';
import ModalViewCompra from '../../compra/factura/componentes/ModalViewCompra.vue';
import ModalViewTranslado from '../../translado/translado/componentes/ModalViewTranslado.vue';
// demos

export default {
    components: { TablaReporte, DosFechas, SelectProductoSucursal, AreaSelect, ModalViewAjuste,ModalViewCompra,ModalViewTranslado },
    props: {
        mostrarFiltro: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const filtros = ref({

            fecha: {
                desde: moment().format('YYYY-MM-DD'),
                hasta: moment().format('YYYY-MM-DD')
            },
            idProductoSucursal: null,
            ProductoSucursal: null,
            desde: null,
            hasta: null,
            idArea: null
        })
        const ModalViewAjusteRef = ref(null)
        const ModalViewCompraRef = ref(null)
        const ModalViewTransladoRef = ref(null)
        const reglas = {

            requerido: [
                v => !!v || "es requerido"
            ],
        }

        const form = ref()
        const validarForm = () => {
            let val = form.value?.validate()


            return val
        }
        const nombreReporte = ref(`Reporte movimientos detallado por producto por tipo transaccion ${filtros.value.fecha.desde} ${filtros.value.fecha.hasta}`)
        const cabecera = ref([

            { text: 'Sucursal', value: 'area.sucursal.nombre', width: '130px', visible: true },
            { text: 'Area', value: 'area.nombre', width: '130px', visible: true },
            { text: 'Barra', value: 'productoSucursal.producto.barra', format: { small: true }, visible: true },
            { text: 'Producto', value: 'productoSucursal.producto.nombre', width: '200px', visible: true },
            { text: 'Lote', value: 'lote.numeroLote', width: '130px', visible: true },
            { text: 'Entradas', value: 'entradas', width: '130px', align: 'right', visible: true },
            { text: 'Salidas', value: 'salidas', width: '130px', align: 'right', visible: true },
            { text: 'Costo', value: 'costo', width: '130px', align: 'right', visible: true },
            { text: 'Costo2', value: 'costo2', width: '130px', align: 'right', visible: false },
            { text: 'Costo3', value: 'costo3', width: '130px', align: 'right', visible: false },
            { text: 'Pvp', value: 'pvp', width: '130px', align: 'right', visible: true },
            { text: 'Iva %', value: 'productoSucursal.producto.tasaImpuesto.valor', width: '130px', align: 'right', visible: true },
            { text: 'Documento', value: 'movimiento.numeroDocumento', width: '200px', visible: true },
            { text: 'Fecha Movimiento', value: 'movimiento.fechaMovimiento', format: { date: true }, width: '160px', visible: true },
            { text: 'Fecha Creacion', value: 'movimiento.fechaCreacion', format: { dateTime: true }, width: '230px', visible: true },
            { text: 'Orden', value: 'orden', visible: true },
        ])
        const datos = ref(
            [
            ]
        )
        const cargando = ref(false)



        const validar = () => {
            let validado = true

            return validado
        }
        const buscarDatosExterno = (dato) => {
            //alert("buscarDatosExterno")
            filtros.value = dato
            cargando.value = true
            MovimientoServices.ConsultarMovimientoDetalladoProductoReporte(
                {
                    idArea: filtros.value.idArea,
                    desde: FuncionesGenerales.formatoFecha(filtros.value.fecha.desde, 5),
                    hasta: FuncionesGenerales.formatoFecha(filtros.value.fecha.hasta, 5),
                    idProductoSucursal: filtros.value.idProductoSucursal,
                })
                .then(response => {
                    if (response.data.estatus = true) {
                        datos.value = response.data.datos
                    } else {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'error',
                        })
                    }

                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    cargando.value = false

                })
        }
        const buscarDatos = () => {
            if (validarForm()) {
                cargando.value = true
                MovimientoServices.ConsultarMovimientoDetalladoProductoReporte(
                    {
                        idArea: filtros.value.idArea,
                        desde: FuncionesGenerales.formatoFecha(filtros.value.fecha.desde, 5),
                        hasta: FuncionesGenerales.formatoFecha(filtros.value.fecha.hasta, 5),
                        idProductoSucursal: filtros.value.ProductoSucursal ? filtros.value.ProductoSucursal.id : -1
                    })
                    .then(response => {
                        if (response.data.estatus = true) {
                            datos.value = response.data.datos
                        } else {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'error',
                            })
                        }

                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        cargando.value = false

                    })
            }
        }

        const getVer = (dato) => {
            console.log(dato)
            if (dato.idTipoTransaccion == 3) {
                ModalViewAjusteRef.value.abrir(dato.movimiento.idDocumento)
            }else if (dato.idTipoTransaccion == 1){
                ModalViewCompraRef.value.abrir(dato.movimiento.idDocumento)
            }else if (dato.idTipoTransaccion == 4) {
                ModalViewTransladoRef.value.abrir(dato.movimiento.idDocumento)
            }

        }

        const getFechas = (datos) => {
            console.log(datos)
            filtros.value.fecha.desde = datos.desde
            filtros.value.fecha.hasta = datos.hasta
        }


        return {
            cabecera,
            datos,
            validar,
            buscarDatos,
            cargando,
            nombreReporte,
            getVer,
            filtros,
            FuncionesGenerales,
            getFechas,
            store,
            form,
            reglas,
            buscarDatosExterno,
            ModalViewAjusteRef,
            ModalViewCompraRef,
            ModalViewTransladoRef
        }
    },
}
</script>
    